import { Injectable } from '@angular/core';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import {
    IGradeColumnAddDTO,
    IGradeSemester,
    IGradeSemesterDTO,
    IGradeSemesterMonth,
    IGradesResultDTO,
    IGradeColumnDTO,
} from 'apps/early-stage-office/src/app/core/models/grade.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IResults } from 'apps/early-stage-office/src/app/core/models/results.interface';
import { parse } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class GradesService {
    constructor(private api: ApiClientService) {}

    public add(groupId: number, gradeColumn: IGradeColumnAddDTO): Observable<void> {
        return this.api.post<void>(`groups/${groupId}/grades`, gradeColumn).pipe(map(() => undefined));
    }

    public get(groupId: number): Observable<IGradeSemester[]> {
        return this.api.get<IResults<IGradesResultDTO>>(`groups/${groupId}/grades`).pipe(map(response => this.parse(response.results)));
    }

    public deleteColumn(groupId: number, columnId: number): Observable<void> {
        return this.api.delete<void>(`groups/${groupId}/gradeDescriptions/${columnId}`);
    }

    public parse(dto: IGradesResultDTO): IGradeSemester[] {
        const semesters = [];

        if (!dto[1]) {
            semesters.push(null);
        }

        for (const key in dto) {
            const element = dto[key] as IGradeSemesterDTO;
            const semester = {
                months: [],
            } as IGradeSemester;

            let latestMonth: string = null;

            for (const keyIn in element) {
                const lessons = element[keyIn];
                semester.months.push(this.parseMonth(lessons, keyIn, key));

                latestMonth = keyIn;
            }

            semester.months.sort((m1, m2) => {
                return new Date(m1.month).getTime() - new Date(m2.month).getTime();
            });

            const examMonth = this.getExamMonth(key, latestMonth, element);
            if (examMonth && examMonth.gradeColumns?.length > 0) {
                semester.months.push(examMonth);
            }

            semesters.push(semester);
        }

        if (dto[2] === undefined && semesters.length === 1) {
            semesters.push(null);
        }

        return semesters;
    }

    private parseMonth(columns: IGradeColumnDTO[], month: string, semester: string): IGradeSemesterMonth {
        return {
            month: parse(month, 'yyyy-MM', new Date()),
            semester: parseInt(semester, 0),
            exam: false,
            gradeColumns: columns
                .filter(c => !c.gradeCategory?.exam)
                .map(c => {
                    return {
                        ...c,
                        grades: c.grades?.filter(g => g.id),
                    };
                }),
        };
    }

    private getExamMonth(semesterIndex: string, month: string, semester: IGradeSemesterDTO): IGradeSemesterMonth {
        const columns: IGradeColumnDTO[] = [];

        for (const keyIn in semester) {
            columns.push(...(semester[keyIn] || []));
        }

        return {
            month: parse(month, 'yyyy-MM', new Date()),
            semester: parseInt(semesterIndex, 0),
            exam: true,
            gradeColumns: columns
                .filter(c => c.gradeCategory?.exam)
                .map(c => {
                    return {
                        ...c,
                        grades: c.grades?.filter(g => g.id),
                    };
                }),
        };
    }
}
