import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import {
    GetClassRegisterGroup,
    LoadLessons,
    UpdateLesson,
    ClearLessons,
    SetCurrentLesson,
    StartLesson,
    HideNavigation,
    AddGrades,
    GetGrades,
    SetCurrentGradesMonth,
    ReloadCurrentLesson,
    ClearGrades,
    DeleteGradeColumn,
    LoadLessonsIfEmpty,
} from './class-register.actions';
import { tap } from 'rxjs/operators';
import { IStudent } from 'apps/early-stage-office/src/app/core/models/student.interface';
import { ClassRegisterGroupService } from 'apps/early-stage-office/src/app/core/services/class-register-group.service';
import { ClassLessonsService } from '../services/class-lessons.service';
import { ISemester, ILesson } from 'apps/early-stage-office/src/app/core/models/lesson.interface';
import { GradesService } from '../services/grades.service';
import {
    IGradeCategory,
    IGradeGroupCategory,
    IGradeGroup,
    IGradeSemester,
    IGradeScale,
    IGradeSemesterMonth,
} from 'apps/early-stage-office/src/app/core/models/grade.interface';
import { of } from 'rxjs';
import { ClassRegisterReportsState, ClassRegisterReportsStateModel } from './children/class-register-reports.state';
import { Injectable } from '@angular/core';
import { IGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { ISchoolYearSettings } from 'apps/early-stage-office/src/app/core/models/school-year.interface';
import { TFeatureType } from '../../../core/models/franchise.interface';
import { ProfileState } from '../../../core/store/profile/profile.state';
import { FranchiseAccessService } from '../../../core/services/franchise-access.service';
import { IRealization } from 'apps/early-stage-office/src/app/core/models/level.interface';
import { differenceInDays, format, getMonth } from 'date-fns';

export interface IClassRegisterStateModel {
    groupId: number;
    group: IGroup;
    currentLesson: ILesson;
    lastLessonHomework: string;
    semesters: ISemester[];
    isNavigationHidden: boolean;
    grades: IGradeSemester[];
    currentGradeMonth: IGradeSemesterMonth;
    reports: ClassRegisterReportsStateModel;
}

@State<IClassRegisterStateModel>({
    name: 'classRegister',
    defaults: {
        groupId: null,
        group: null,
        semesters: null,
        lastLessonHomework: null,
        currentLesson: null,
        isNavigationHidden: false,
        grades: null,
        currentGradeMonth: null,
        reports: null,
    },
    children: [ClassRegisterReportsState],
})
@Injectable()
export class ClassRegisterState {
    constructor(
        private groupService: ClassRegisterGroupService,
        private lessonsService: ClassLessonsService,
        private gradesService: GradesService
    ) {}

    @Selector()
    public static getState(state: IClassRegisterStateModel): IClassRegisterStateModel {
        return state;
    }

    @Selector()
    public static groupId(state: ClassRegisterReportsStateModel) {
        return state.groupId;
    }

    @Selector()
    public static group(state: IClassRegisterStateModel): IGroup {
        return state.group;
    }

    @Selector()
    public static realization(state: IClassRegisterStateModel): IRealization {
        return state.group?.realization || null;
    }

    @Selector()
    public static schoolYearSettings(state: IClassRegisterStateModel): ISchoolYearSettings {
        return state.group?.schoolYearSettings;
    }

    @Selector()
    public static currentGradeMonth(state: IClassRegisterStateModel): IGradeSemesterMonth {
        return state.currentGradeMonth;
    }

    @Selector()
    public static gradeGroup(state: IClassRegisterStateModel): IGradeGroup {
        return state.group ? state.group.level.gradeGroup : null;
    }

    @Selector()
    public static useGrades(state: IClassRegisterStateModel): boolean {
        return state.group ? state.group.useGrades : undefined;
    }

    @Selector()
    public static gradeCategories(state: IClassRegisterStateModel): IGradeCategory[] {
        return state.group ? state.group.level.gradeGroup.gradeCategories?.filter(g => !g.exam) : [];
    }

    @Selector()
    public static examGradeCategories(state: IClassRegisterStateModel): IGradeCategory[] {
        return state.group ? state.group.level.gradeGroup.gradeCategories?.filter(g => g.exam) : [];
    }

    @Selector()
    public static gradeScales(state: IClassRegisterStateModel): IGradeScale[] {
        return state.group ? state.group.level.gradeGroup.gradeScales : [];
    }

    @Selector()
    public static gradeGroupCategories(state: IClassRegisterStateModel): IGradeGroupCategory[] {
        return state.group ? state.group.level.gradeGroup.gradeGroupCategories : [];
    }

    @Selector()
    public static navigationHidden(state: IClassRegisterStateModel): boolean {
        return state.isNavigationHidden;
    }

    @Selector()
    public static id(state: IClassRegisterStateModel): number {
        return state.group.id;
    }

    @Selector()
    public static currentLesson(state: IClassRegisterStateModel): ILesson {
        return state.currentLesson;
    }

    @Selector()
    public static grades(state: IClassRegisterStateModel): IGradeSemester[] {
        return state.grades;
    }

    @Selector()
    public static color(state: IClassRegisterStateModel): string {
        return state.group.color?.background || '#ccc';
    }

    @Selector()
    public static semesters(state: IClassRegisterStateModel): ISemester[] {
        return state.semesters;
    }

    @Selector()
    public static allLessons(state: IClassRegisterStateModel): ILesson[] {
        if (!state.semesters) {
            return null;
        }

        let lessons: ILesson[] = [];
        state.semesters.forEach(s => {
            if (s) {
                s.months.forEach(m => {
                    lessons = lessons.concat(m.lessons);
                });
            }
        });

        return lessons;
    }

    @Selector()
    public static nearestLesson(state: IClassRegisterStateModel): ILesson {
        if (!state.semesters) {
            return null;
        }

        let lessons: ILesson[] = [];
        state.semesters.forEach(s => {
            if (s) {
                s.months.forEach(m => {
                    lessons = lessons.concat(m.lessons);
                });
            }
        });

        return lessons.find(l => l.timeStatus.nearest || l.timeStatus.current);
    }

    public static lesson(id: number) {
        return createSelector([this], (state: IClassRegisterStateModel) => {
            return this.allLessons(state).find(l => l.id === id);
        });
    }

    public static lessonsByMonth(month: number, semester: number = null) {
        return createSelector([this], (state: IClassRegisterStateModel) => {
            if (!state.semesters) {
                return null;
            }

            let lessons: ILesson[] = [];
            state.semesters.forEach((s, index) => {
                if (semester !== null && semester - 1 !== index) {
                    return;
                }

                s.months.forEach(m => {
                    if (getMonth(m.month) + 1 === month) {
                        lessons = lessons.concat(m.lessons);
                    }
                });
            });

            return lessons;
        });
    }

    public static lessonsBySemester(semester: number) {
        return createSelector([this], (state: IClassRegisterStateModel) => {
            if (!state.semesters) {
                return null;
            }

            let lessons: ILesson[] = [];
            state.semesters[semester - 1].months.forEach(m => {
                lessons = lessons.concat(m.lessons);
            });

            return lessons.filter(l => !l.isWorkshop);
        });
    }

    @Selector()
    public static allStudents(state: IClassRegisterStateModel): IStudent[] {
        return state.group.students;
    }

    @Selector()
    public static studentsByCurrentLesson(state: IClassRegisterStateModel): IStudent[] {
        if (!state.group?.students || !state.currentLesson) {
            return null;
        }

        return state.group.students.filter(s => !s.nextGroupFirstLessonDate || differenceInDays(state.currentLesson.date, s.nextGroupFirstLessonDate) >= 0);
    }

    @Selector()
    public static studentsByCurrentDate(state: IClassRegisterStateModel): IStudent[] {
        if (!state.group?.students) {
            return null;
        }

        return state.group.students.filter(
            s =>
                !s.nextGroupFirstLessonDate ||
                format(s.nextGroupFirstLessonDate, 'yyyy-MM-dd') === format(state.group.startedAt, 'yyyy-MM-dd') ||
                differenceInDays(s.nextGroupFirstLessonDate, new Date()) <= 0
        );
    }

    public static hasAccess(type: TFeatureType) {
        return createSelector([this, ProfileState], (state: IClassRegisterStateModel) => {
            if (!state.group.franchise) {
                return false;
            }

            return FranchiseAccessService.checkAccess(type, state.group.franchise);
        });
    }

    @Action(GetClassRegisterGroup)
    public getClassRegisterGroup({ patchState }: StateContext<IClassRegisterStateModel>, { payload }: GetClassRegisterGroup) {
        patchState({
            groupId: payload.id,
        });

        patchState({
            currentLesson: null,
        });

        return this.groupService.get(payload.id).pipe(
            tap(group => {
                patchState({
                    group,
                });
            })
        );
    }

    @Action(SetCurrentLesson)
    public setCurrentLesson({ patchState }: StateContext<IClassRegisterStateModel>, { payload }: SetCurrentLesson) {
        patchState({
            currentLesson: payload.lesson,
        });
    }

    @Action(StartLesson)
    public startLesson({ getState, patchState }: StateContext<IClassRegisterStateModel>, { payload }: StartLesson) {
        const groupId = getState().group.id;
        return this.lessonsService.startLesson(groupId, payload.id).pipe(
            tap(l => {
                const current = getState().currentLesson;
                const group = getState().group;
                if (l?.id === current?.id) {
                    patchState({
                        currentLesson: l,
                    });
                }
                group.startedLessonDays++;
                patchState({
                    group,
                });
            })
        );
    }

    @Action(LoadLessonsIfEmpty)
    public loadLessonsIfEmpty({ patchState, getState }: StateContext<IClassRegisterStateModel>) {
        if (getState().semesters && getState().semesters.length > 0) {
            return of(true);
        }

        const groupId = getState().group.id;

        return this.lessonsService.get(groupId).pipe(
            tap(results => {
                patchState({ semesters: results });
            })
        );
    }

    @Action(LoadLessons)
    public loadLessons({ patchState, getState }: StateContext<IClassRegisterStateModel>) {
        const groupId = getState().group.id;

        return this.lessonsService.get(groupId).pipe(
            tap(results => {
                patchState({ semesters: results });
            })
        );
    }

    @Action(ClearLessons)
    public clearLesson({ patchState }: StateContext<IClassRegisterStateModel>) {
        patchState({
            semesters: [],
        });
    }

    @Action(UpdateLesson)
    public updateLesson({ patchState, getState }: StateContext<IClassRegisterStateModel>, { payload }: UpdateLesson) {
        const groupId = getState().group.id;

        return this.lessonsService.updateLesson(groupId, payload.id, payload.lesson).pipe(
            tap(result => {
                const semesters = getState().semesters;

                if (semesters && result) {
                    const semester = semesters[result.semester - 1];

                    if (semester) {
                        semester.months.forEach(month => {
                            month.lessons = month.lessons.map(lesson => {
                                if (lesson.id === result.id) {
                                    for (const key in result) {
                                        lesson[key] = result[key];
                                    }
                                }

                                return lesson;
                            });
                        });
                    }
                }

                const current = getState().currentLesson;

                if (current && result.id === current.id) {
                    patchState({
                        currentLesson: result,
                    });
                }

                patchState({ semesters });
            })
        );
    }

    @Action(ReloadCurrentLesson)
    public reloadCurrentLesson({ patchState, getState }: StateContext<IClassRegisterStateModel>, { payload }: ReloadCurrentLesson) {
        const groupId = getState().group.id;
        const currentLesson = getState().currentLesson;
        const lessonId = currentLesson?.id || payload.id;

        if (!lessonId) {
            return of(null);
        }

        return this.lessonsService.getOne(groupId, lessonId).pipe(
            tap(result => {
                const semesters = getState().semesters;

                if (semesters && result) {
                    const semester = semesters[result.semester - 1];

                    if (semester) {
                        semester.months.forEach(month => {
                            month.lessons = month.lessons.map(lesson => {
                                if (lesson.id === result.id) {
                                    for (const key in result) {
                                        lesson[key] = result[key];
                                    }
                                }

                                return lesson;
                            });
                        });
                    }
                }

                patchState({
                    currentLesson: result,
                });

                patchState({ semesters });
            })
        );
    }

    @Action(AddGrades)
    public addGrades({ getState }: StateContext<IClassRegisterStateModel>, { payload }: AddGrades) {
        const groupId = getState().group.id;

        return this.gradesService.add(groupId, payload.gradeColumn).pipe(tap(() => {}));
    }

    @Action(GetGrades)
    public getGrades({ patchState, getState }: StateContext<IClassRegisterStateModel>) {
        const groupId = getState().group.id;

        return this.gradesService.get(groupId).pipe(
            tap(result => {
                patchState({
                    grades: result,
                });

                /*const current = getState().currentGradeMonth;

                if (current) {
                    const currentDate = current.month.format('YYYY-MM');

                    for (const semester of result) {
                        if (!semester) {
                            continue;
                        }

                        const newCurrent = semester.months.find(m => m.month.format('YYYY-MM') === currentDate);
                        if (newCurrent) {
                            patchState({
                                currentGradeMonth: newCurrent
                            });
                        }
                    }
                }*/
            })
        );
    }

    @Action(DeleteGradeColumn)
    public deleteGradeColumn({ getState }: StateContext<IClassRegisterStateModel>, { payload }: DeleteGradeColumn) {
        const groupId = getState().group.id;

        return this.gradesService.deleteColumn(groupId, payload.id);
    }

    @Action(ClearGrades)
    public clearGrades({ patchState }: StateContext<IClassRegisterStateModel>) {
        patchState({
            grades: [],
            currentGradeMonth: null,
        });
    }

    @Action(SetCurrentGradesMonth)
    public setCurrentGradesMonth({ patchState }: StateContext<IClassRegisterStateModel>, { payload }: SetCurrentGradesMonth) {
        patchState({
            currentGradeMonth: payload.currentGradesMonth,
        });
    }

    @Action(HideNavigation)
    public hideNavigation({ patchState }: StateContext<IClassRegisterStateModel>, { payload }: HideNavigation) {
        patchState({
            isNavigationHidden: payload.hide,
        });
    }
}
