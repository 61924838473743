import { IGroupDTO, IGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { Observable } from 'rxjs';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IResults } from 'apps/early-stage-office/src/app/core/models/results.interface';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GroupsService } from './groups.service';

@Injectable({
    providedIn: 'root',
})
export class ClassRegisterGroupService {
    constructor(
        public api: ApiClientService,
        private groups: GroupsService
    ) {}

    public get(id: number): Observable<IGroup> {
        return this.api.get<IResults<IGroupDTO>>(`groups/${id}`).pipe(map(response => this.parse(response.results)));
    }

    public parse(dto: IGroupDTO): IGroup {
        return this.groups.parse(dto);
    }
}
