import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import {
    LoadReports,
    EditReport,
    ResetReports,
    SetClassRegisterGroup,
    ClearCurrentReportStudent,
    EditReportStudent,
    LoadReportStudent,
    SendReportStudents,
    EditReportDate,
    LoadReport,
    ReloadCurrentReportStudent,
} from './class-register-reports.actions';
import { catchError, tap } from 'rxjs/operators';
import { ReportsService } from '../../services/reports.service';
import { IReport, IReportStudent } from 'apps/early-stage-office/src/app/core/models/report.interface';
import { StateErrorHandlerService } from 'apps/early-stage-office/src/app/core/services/paginable-state-error-handler.service';
import { ReportStudentService } from '../../services/report-student.service';
import { Injectable } from '@angular/core';
import { GetClassRegisterGroup } from '../class-register.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

export interface ClassRegisterReportsStateModel {
    reports: { [key: number]: IReport[] };
    groupId: number;
    currentReportStudent: IReportStudent;
}

@State<ClassRegisterReportsStateModel>({
    name: 'reports',
    defaults: {
        reports: {},
        groupId: null,
        currentReportStudent: null,
    },
})
@Injectable()
export class ClassRegisterReportsState {
    constructor(
        public reports: ReportsService,
        public reportStudents: ReportStudentService,
        public errorHandler: StateErrorHandlerService
    ) {}

    @Selector()
    public static getState(state: ClassRegisterReportsStateModel) {
        return state;
    }

    public static reports(type: number) {
        return createSelector([this], (state: ClassRegisterReportsStateModel) => {
            if (!state.reports || !state.reports[type]) {
                return null;
            }

            return state.reports[type];
        });
    }

    @Selector()
    public static groupId(state: ClassRegisterReportsStateModel) {
        return state.groupId;
    }

    public static report(id: number) {
        return createSelector([this], (state: ClassRegisterReportsStateModel) => {
            if (!state.reports) {
                return null;
            }
            for (const key in state.reports) {
                if (Object.prototype.hasOwnProperty.call(state.reports, key)) {
                    const element = state.reports[key].find(r => r.id === id);

                    if (element) {
                        return element;
                    }
                }
            }
            return null;
        });
    }

    @Selector()
    public static currentReportStudent(state: ClassRegisterReportsStateModel): IReportStudent {
        return state.currentReportStudent;
    }

    @Action(GetClassRegisterGroup)
    public getClassRegisterGroup({ patchState }: StateContext<ClassRegisterReportsStateModel>, { payload }: GetClassRegisterGroup) {
        patchState({
            groupId: payload.id,
        });
    }

    @Action(SetClassRegisterGroup)
    public setClassRegisterGroup({ patchState }: StateContext<ClassRegisterReportsStateModel>, { payload }: SetClassRegisterGroup) {
        patchState({
            groupId: payload.id,
        });
    }

    @Action(ClearCurrentReportStudent)
    public clearCurrentReportStudent({ patchState }: StateContext<ClassRegisterReportsStateModel>) {
        patchState({
            currentReportStudent: null,
        });
    }

    @Action(LoadReports)
    public loadReports(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: LoadReports) {
        const groupId = ctx.getState().groupId;
        const params = {
            'filter[type]': payload.type,
        };

        return this.reports.get(groupId, params).pipe(
            tap(response => {
                const reports = ctx.getState().reports;
                reports[payload.type] = response;
                ctx.patchState({
                    reports,
                });
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(LoadReport)
    public loadReport(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: LoadReport) {
        const groupId = ctx.getState().groupId;
        return this.reports.getOne(payload.id, groupId).pipe(
            tap(response => {
                this.patchReport(ctx, response);
            })
        );
    }

    @Action(EditReport)
    public editReport(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: EditReport) {
        const groupId = ctx.getState().groupId;

        return this.reports.edit(payload.data, groupId).pipe(
            tap((result: IReport) => {
                this.patchReport(ctx, result);
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(EditReportDate)
    public editReportDate(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: EditReportDate) {
        const groupId = ctx.getState().groupId;

        return this.reports.editDate(payload.data, groupId).pipe(
            tap((result: IReport) => {
                this.patchReport(ctx, result);
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(SendReportStudents)
    public sendReportStudents(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: SendReportStudents) {
        const groupId = ctx.getState().groupId;

        return this.reports.send(payload.data, groupId, payload.reportId).pipe(
            tap((result: IReport) => {
                this.patchReport(ctx, result);
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(EditReportStudent)
    public editReportStudent(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: EditReportStudent) {
        const groupId = ctx.getState().groupId;

        return this.reportStudents.edit(payload.data, groupId, payload.reportId).pipe(
            tap(async (reportStudent: IReportStudent) => {
                ctx.patchState({
                    currentReportStudent: reportStudent,
                });
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(LoadReportStudent)
    public loadReportStudent(ctx: StateContext<ClassRegisterReportsStateModel>, { payload }: LoadReportStudent) {
        const groupId = ctx.getState().groupId;

        return this.reportStudents.getOne(payload.id, groupId, payload.reportId).pipe(
            tap(reportStudent => {
                ctx.patchState({
                    currentReportStudent: reportStudent,
                });
            }),
            catchError(e => this.catchError(e))
        );
    }
    @Action(ReloadCurrentReportStudent)
    public reloadCurrentReportStudent(ctx: StateContext<ClassRegisterReportsStateModel>) {
        const groupId = ctx.getState().groupId;

        const currentReport = ctx.getState().currentReportStudent;

        if (!currentReport) {
            return;
        }

        return this.reportStudents.getOne(currentReport.id, groupId, currentReport.reportId).pipe(
            tap(reportStudent => {
                ctx.patchState({
                    currentReportStudent: reportStudent,
                });
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(ResetReports)
    public resetReportCollection(ctx: StateContext<ClassRegisterReportsStateModel>) {
        ctx.patchState({
            currentReportStudent: null,
            reports: {},
        });
    }

    private patchReport(ctx: StateContext<ClassRegisterReportsStateModel>, result: IReport) {
        const reports = ctx.getState().reports;
        let reportsType = reports[result.type?.id] || [];

        if (!reportsType.some(r => r.id === result.id)) {
            reportsType.push(result);
        } else {
            reportsType = reportsType.map(r => (r.id === result.id ? result : r));
        }
        reports[result.type?.id] = reportsType;

        ctx.patchState({
            reports,
        });
    }

    private catchError(e: HttpErrorResponse): Observable<never> {
        this.errorHandler.parseError(e);
        return throwError(e);
    }
}
