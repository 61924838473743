import { IReportAddDTO, IReportStudentAddDTO, IReportStudentsSendDTO, IReportDateEditDTO } from 'apps/early-stage-office/src/app/core/models/report.interface';

export class SetClassRegisterGroup {
    public static readonly type = '[ClassRegister] SetClassRegisterGroup';
    constructor(public payload: { id: number }) {}
}

export class ClearCurrentReportStudent {
    public static readonly type = '[ClassRegister] ClearCurrentReportStudent';
    constructor() {}
}

export class ResetReports {
    public static readonly type = '[ClassRegister] ResetReports';
    constructor() {}
}

export class LoadReports {
    public static readonly type = '[ClassRegister] LoadReports';
    constructor(public payload: { type: number }) {}
}

export class LoadReport {
    public static readonly type = '[ClassRegister] LoadReport';
    constructor(public payload: { id: number }) {}
}

export class LoadReportStudent {
    public static readonly type = '[ClassRegister] LoadReportStudent';
    constructor(public payload: { id: number; reportId: number }) {}
}

export class ReloadCurrentReportStudent {
    public static readonly type = '[ClassRegister] ReloadCurrentReportStudent';
    constructor() {}
}

export class EditReport {
    public static readonly type = '[ClassRegister] EditReport';
    constructor(public payload: { data: IReportAddDTO }) {}
}

export class EditReportDate {
    public static readonly type = '[ClassRegister] EditReportDate';
    constructor(public payload: { data: IReportDateEditDTO }) {}
}

export class SendReportStudents {
    public static readonly type = '[ClassRegister] SendReportStudents';
    constructor(public payload: { data: IReportStudentsSendDTO; reportId: number }) {}
}

export class EditReportStudent {
    public static readonly type = '[ClassRegister] EditReportStudent';
    constructor(public payload: { data: IReportStudentAddDTO; reportId: number }) {}
}
