import { Injectable } from '@angular/core';
import {
    IReportStudent,
    IReportStudentDTO,
    IReportStudentAddDTO,
    IReportStudentStatus,
    IReportStudentField,
} from 'apps/early-stage-office/src/app/core/models/report.interface';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IResults } from '../../../core/models/results.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReportStudentService {
    constructor(protected api: ApiClientService) {}

    public getPath(groupId: number, reportId: number) {
        return `groups/${groupId}/reports/${reportId}/reportStudents`;
    }

    public edit(data: IReportStudentAddDTO, groupId: number, reportId: number) {
        return this.api
            .patch<IResults<IReportStudentDTO>>(`${this.getPath(groupId, reportId)}/${data.id}`, data)
            .pipe(map(response => this.parse(response.results, reportId)));
    }

    public getOne(id: number, groupId: number, reportId: number): Observable<IReportStudent> {
        return this.api
            .get<IResults<IReportStudentDTO>>(`${this.getPath(groupId, reportId)}/${id}`)
            .pipe(map(response => this.parse(response.results, reportId)));
    }

    public parse(studentDto: IReportStudentDTO, reportId: number): IReportStudent {
        const student: IReportStudent = {
            id: studentDto.id,
            student: {
                ...studentDto.student,
            },
            dateFrom: studentDto.dateFrom,
            dateTo: studentDto.dateTo,
            sentAt: studentDto.sentAt,
            sentBy: studentDto.sentBy,
            url: studentDto.url,
            other: [],
            areas: [],
            radioGroups: [],
            certificate: null,
            exam: [],
            sections: [],
            status: studentDto.status,
            studentGrades: [],
            counters: null,
            reportName: studentDto.reportName,
            corrections: studentDto.corrections,
            reportId: reportId,
        };

        if (studentDto.sendLogUniqueDecoded) {
            student.sendLogUniqueDecoded = studentDto.sendLogUniqueDecoded;
        }

        if (studentDto.counters) {
            student.counters = studentDto.counters.student;
        }

        if (studentDto.studentGradeDescriptionsCategories) {
            for (const gradeKey in studentDto.studentGradeDescriptionsCategories) {
                const element = studentDto.studentGradeDescriptionsCategories[gradeKey];

                student.studentGrades.push({
                    category: element[0].gradeCategory,
                    gradeColumns: element,
                });
            }

            student.studentGrades.sort((a, b) => {
                return a.category.id > b.category.id ? 1 : -1;
            });
        }

        if (studentDto.fieldsWithValues) {
            for (const field of studentDto.fieldsWithValues) {
                const parsedField: IReportStudentField = {
                    ...field,
                    gradeDescription: field?.gradeDescription
                        ? {
                              ...field.gradeDescription,
                              grades: field.gradeDescription.grades?.filter(g => g.id),
                          }
                        : undefined,
                };

                if (field.groupType === 'exam') {
                    student.exam.push(parsedField);
                } else if (field.groupType === 'comment') {
                    student.other.push(parsedField);
                } else if ((field.type === 'radioGroup' || field.type === 'header' || field.type === 'radioGroupHeader') && field.groupType === '') {
                    student.radioGroups.push(parsedField);
                } else if (field.groupType === 'certificate') {
                    student.certificate = parsedField;
                }
            }

            /*const groupedFields = studentDto.fieldsWithValues.reduce((r, a) => {
                r[a.groupName] = [...r[a.groupName] || [], a];
                return r;
            }, {}) as { [key: string]: IReportStudentField[] };

            for (const fieldGroup in groupedFields) {
                if (groupedFields.hasOwnProperty(fieldGroup)) {
                    const element = groupedFields[fieldGroup];

                    if (element[0].groupType === 'other') {
                        student.other.push(...element);
                        continue;
                    }

                    if (element[0].groupType === '') {
                        continue;
                    }

                    if (element[0].groupType === 'exam') {
                        student.exam.push()

                        continue;
                    }



                    const section = {} as IReportStudentSection;

                    for (const field of element) {
                        switch (field.name) {
                            case field.groupName:
                                section.select = field;
                                break;
                            case field.groupName + 'Comment':
                                section.comment = field;
                                break;
                            case field.groupName + 'Other':
                                section.other = field;
                                break;
                        }
                    }

                    if (element[0].groupType === 'section') {
                        student.sections.push(section);
                    }

                    if (element[0].groupType === 'area') {
                        student.areas.push(section);
                    }

                }
            }*/
        }

        if (studentDto.nextReportStudent) {
            student.nextReportStudent = studentDto.nextReportStudent;
        }

        return student;
    }

    public getStatusName(status: IReportStudentStatus): string {
        const types = ['Draft', 'Review', 'Ready', 'Sent'];
        return types[status];
    }
}
