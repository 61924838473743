import { Injectable } from '@angular/core';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IReport, IReportAddDTO, IReportStudentsSendDTO, IReportDateEditDTO } from 'apps/early-stage-office/src/app/core/models/report.interface';
import { Observable } from 'rxjs';
import { IResults } from 'apps/early-stage-office/src/app/core/models/results.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    constructor(protected api: ApiClientService) {}

    public getPath(groupId: number) {
        return `groups/${groupId}/reports`;
    }

    public get(groupId: number, params: { [key: string]: unknown } = {}): Observable<IReport[]> {
        return this.api.get<IResults<IReport[]>>(this.getPath(groupId), { params }).pipe(
            map(dtos => {
                return Array.isArray(dtos.results) ? dtos.results.map(u => u) : [];
            })
        );
    }

    public getOne(id: number, groupId: number): Observable<IReport> {
        return this.api.get<IResults<IReport>>(`${this.getPath(groupId)}/${id}`).pipe(map(response => response.results));
    }

    public create(data: IReportAddDTO, groupId: number): Observable<IReport> {
        return this.api.post<IResults<IReport>>(this.getPath(groupId), data).pipe(map(response => response.results));
    }

    public edit(data: IReportAddDTO, groupId: number) {
        return this.api.patch<IResults<IReport>>(this.getPath(groupId) + '/' + data.id, data).pipe(map(response => response.results));
    }

    public editDate(data: IReportDateEditDTO, groupId: number): Observable<IReport> {
        return this.api.patch<IResults<IReport>>(`groups/${groupId}/reports/${data.id}`, data).pipe(map(response => response.results));
    }

    public send(data: IReportStudentsSendDTO, groupId: number, reportId: number): Observable<IReport> {
        return this.api.post<IResults<IReport>>(`groups/${groupId}/reports/${reportId}/send`, data).pipe(map(response => response.results));
    }

    public download(groupId: number, reportId: number, studentId: number): Observable<string> {
        return this.api.get<IResults<string>>(`${this.getPath(groupId)}/${reportId}/reportStudents/${studentId}/json`).pipe(
            map(response => {
                return response.results;
            })
        );
    }
}
